import classNames from "classnames"
import React, { useContext, useRef } from "react"

import { Color } from "../../../../constants/V2/color"
import PillButton from "../../../elements/V2/Buttons/PillButton"
import TextButton from "../../../elements/V2/Buttons/TextButton"
import Callout from "../../../elements/V2/Callout"
import GatsbyStoryblokImage from "../../../elements/V2/GatsbyStoryblokImage"
import Grid from "../../../elements/V2/Grid"
import Section from "../../../elements/V2/Section"
import StickyContainer from "../../../elements/V2/StickyContainer"
import Typography from "../../../elements/V2/Typography"
import Video from "../../../elements/V2/Video"
import { NavigationV2Context } from "../../Navigation/context"

import { useElementObserver } from "@hooks/V2/useElementObserver"
import {
  getBackgroundColorClass,
  getAccentColor,
  getGradientFromColorClass,
  getGradientToColorClass,
} from "@utils/V2/color"
import { useIsBreakpoint } from "@utils/V2/screen"

type Props = {
  headingText: string
  headingSize?: "display1" | "h1" | "h2" | "h3" | "h4" | "h5"
  bodyText: string
  primaryBackgroundColor: Color
  secondaryBackgroundColor?: Color
  primaryButtonText?: string
  primaryButtonLink?: string
  primaryButtonMobileLink?: string
  primaryButtonBorderColor: Color
  secondaryButtonText: string
  secondaryButtonLink: string
  videoUrl?: string
  imageUrl: string
  imageAlt: string
  callout?: Storyblok.Callout
  hasScrollArrow?: boolean
} & Partial<Storyblok.CTATrackingEvent>

const CentreHero = ({
  headingText,
  headingSize = "h2",
  bodyText,
  primaryBackgroundColor,
  secondaryBackgroundColor,
  primaryButtonText,
  primaryButtonLink,
  primaryButtonMobileLink,
  primaryButtonBorderColor,
  secondaryButtonText,
  secondaryButtonLink,
  videoUrl,
  imageUrl,
  imageAlt,
  callout,
  trackingEvent,
  trackingEventKey,
  trackingEventValue,
  hasScrollArrow = false,
  ...props
}: Props) => {
  const navigationContext = useContext(NavigationV2Context)
  const textContainerRef = useRef(null)
  const { isVisible: isCtaVisible, startObserving } =
    useElementObserver(textContainerRef)
  const accentColor = getAccentColor(primaryBackgroundColor)
  const isMobile = useIsBreakpoint("mobile")

  if (isMobile) {
    startObserving()
  }

  return (
    <Section
      className={classNames(
        "-mb-lg pb-64 pt-72 md:py-88 lg:py-144",
        !secondaryBackgroundColor
          ? getBackgroundColorClass(primaryBackgroundColor)
          : [
              "bg-gradient-to-br",
              getGradientFromColorClass(primaryBackgroundColor),
              getGradientToColorClass(secondaryBackgroundColor),
            ]
      )}
      {...props}
    >
      <Grid>
        <div
          ref={textContainerRef}
          className="col-span-full md:col-start-2 md:col-end-6 lg:col-start-3 lg:col-end-11"
        >
          {callout && (
            <Callout
              color={getAccentColor(primaryBackgroundColor)}
              classname="mx-auto mb-16"
              text={callout.text}
              highlightedWord={callout.highlightedWord}
            />
          )}
          <Typography
            text={headingText}
            font="grotesk"
            weight="medium"
            size={headingSize}
            color={accentColor}
            className="mb-8 text-center md:mb-16"
            element="h1"
          />
          <Typography
            text={bodyText}
            font="grotesk"
            weight="book"
            size="body-lg"
            color={accentColor}
            className="mb-20 text-center md:mb-32"
          />
          <div className="mb-20 flex flex-col items-center justify-center gap-20 sm:mb-24 sm:flex-row sm:items-baseline md:gap-24 lg:mb-56 lg:gap-32">
            {primaryButtonText && primaryButtonLink && (
              <PillButton
                size="large"
                style="solid"
                text={primaryButtonText}
                color={primaryButtonBorderColor}
                linkUrl={primaryButtonLink}
                mobileLinkUrl={primaryButtonMobileLink}
                trackingEvent={trackingEvent}
                trackingEventKey={trackingEventKey}
                trackingEventValue={trackingEventValue}
              />
            )}
            <TextButton
              style="text"
              text={secondaryButtonText}
              linkUrl={secondaryButtonLink}
              color={accentColor}
            />
          </div>
          {primaryButtonText && primaryButtonLink && (
            <StickyContainer
              isVisible={
                typeof isCtaVisible === "boolean" &&
                !isCtaVisible &&
                !navigationContext.isMobileMenuOpen
              }
            >
              <PillButton
                size="large"
                style="solid"
                text={primaryButtonText}
                overrideTextColor={accentColor}
                color={primaryButtonBorderColor}
                linkUrl={primaryButtonLink}
                mobileLinkUrl={primaryButtonMobileLink}
                trackingEvent={trackingEvent}
                trackingEventKey={trackingEventKey}
                trackingEventValue={trackingEventValue}
                hasScrollArrow={hasScrollArrow}
              />
            </StickyContainer>
          )}
        </div>
        <div className="col-span-full text-center">
          {videoUrl ? (
            <Video
              video={videoUrl}
              coverImage={imageUrl}
              ImageAlt={imageAlt || "Hero image"}
              loadType="eager"
              aspectRatio="16:9"
              playbackMode="autoplay"
              rounded
              playOnMobile={false}
            />
          ) : (
            <GatsbyStoryblokImage
              image={imageUrl}
              alt={imageAlt || "Hero image"}
              loadType="eager"
              quality={80}
              aspectRatio="16:9"
              rounded
            />
          )}
        </div>
      </Grid>
    </Section>
  )
}

export default CentreHero
